<template>
    <div class="gutter">
        <div class="headline blue">Mina fakturor</div>
        <p>Nedan hittar du dina fakturor.</p>

        <div class="paid_invoice" v-if="paid_invoice_id > 0">
            <h3><i class="mdi mdi-check"></i> Faktura #{{ paid_invoice_id }} är nu betalad!</h3>
            <p>Tack för att du betalar din faktura</p>
        </div>

        <p class="no-invoices grey" v-if="invoices.length <= 0">Du har inga fakturor.</p>
        <div class="invoices" v-if="invoices.length > 0">
            <div class="invoice" v-for="invoice in invoices" :key="invoice.id">
                <div class="invoice-row">
                    <div class="title">
                        {{ invoice.title }}
                    </div>
                    <div>
                        {{ invoice.total }} SEK
                    </div>
                    <div>
                        <i class="mdi mdi-cash" :class="(invoice.status === '4') ? 'green' : 'red'"></i>
                    </div>
                    <div>
                        {{ invoice.due_date }}
                    </div>
                </div>
                <div class="button-row" v-if="invoice.status === '2' || invoice.status === '3'">
                    <button @click="showSwitchMembership(invoice.id, invoice.membershiptype_id)">Byt medlemskap</button>
                    <button class="pay" @click="openInNewTab(invoice.recipient_url)">Betala</button>
                </div>
            </div>
        </div>

        <Modal ref="modal_pay_confirm">
            <template slot="header">
                <div class="header">
                    Du dirigeras nu om..
                </div>
            </template>
            <template slot="body">
                <p>Du kommer nu att bli skickad till vår betallösning Payer för betalning av faktura</p>
                <div class="button-group">
                    <button class="blue" v-on:click="submitPaymentForm()">OK</button>
                    <button v-on:click="$refs.modal_pay_confirm.close()">Avbryt</button>
                </div>
            </template>
        </Modal>

        <Modal ref="modal_switch_membership">
            <template slot="header">
                <div class="header">
                    Byt medlemskap
                </div>
            </template>
            <template slot="body">
                <p>
                    Välj det medlemskap du vill byta till.
                </p>
                <p class="switch-explanation">
                    Den nuvarande fakturan kommer att makuleras och sedan skapas en ny som reflekterar det nya medlemskapet.
                </p>
                <Loader type="component"></Loader>

                <div v-if="available_memberships && available_memberships.length">
                    <div v-for="(item, index) in available_memberships" v-bind:key="item.id" :index="index" class="member-card">
                        <div class="custom-radio">
                            <input :id="'mtype_'+item.id" type="radio" name="membershiptype" v-model="switch_to_type" :value="item.id" />
                            <label :for="'mtype_'+item.id">
                                {{ item.title }} ({{ item.price }} SEK)
                                <span v-if="item.description" v-html="item.description"></span>
                            </label>
                            <label :for="'mtype_'+item.id" class="radio-gfx">
                                <label :for="'mtype_'+item.id" class="circle-wrapper"></label>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="button-group">
                    <button class="blue" v-on:click="submitMembershipSwitch()">Spara</button>
                    <button v-on:click="$refs.modal_switch_membership.close()">Avbryt</button>
                </div>
            </template>
        </Modal>
    </div>
</template>


<script>
    import {mapActions, mapState} from "vuex";
    import apiCall from "@/utils/api";
    import Modal from "@/components/Modal";
    import Loader from "@/components/Loader";

    export default {
        components: {
            Modal,
            Loader
        },
        name: "Billing",
        data(){
            return {
                invoices: [
                    /*{
                        title: "testing testin",
                        total: 1200,
                        due_date: '2018-01-01',
                        status: '1'
                    }*/
                ],
                available_memberships: [],
                original_membership_type: null,
                switch_to_type: null,
                switching_from_order: null,
                paid_invoice_id: this.$route.params.invoice_number,
                payer_agentid: null,
                payer_xml_writer: null,
                payer_data: null,
                payer_checksum: null,
                payer_charset: null,
                payer_server: null
            }
        },
        computed: {
            ...mapState({
                user_data: state => state.user.data
            })
        },
        mounted() {
            this.loadOrders();
        },
        methods: {
            openInNewTab(url) {
              window.open(url, '_blank', 'noreferrer');
            },
            loadOrders() {
                let data = {

                };
                return apiCall("/user/getMemberOrders", {data}).then(res => {
                    this.invoices = res;
                });
            },
            pay(id) {
                let data = {
                    invoice_number: parseInt(id)
                };
                // apiCall("/user/getPaymentUrl", {data}).then(res => {
                //     this.payer_agentid = res.agent_id;
                //     this.payer_charset = res.charset;
                //     this.payer_checksum = res.checksum;
                //     this.payer_data = res.xml_data;
                //     this.payer_xml_writer = res.api_version;
                //     this.payer_server = res.server_url;
                //     this.$refs.modal_pay_confirm.showModal();
                // });
            },
            submitPaymentForm() {
                this.$refs.payment_form.submit();
            },

            showSwitchMembership(orderId, currentMembershipType) {
                this.original_membership_type = currentMembershipType;
                this.switch_to_type = currentMembershipType;
                this.switching_from_order = orderId;
                this.$refs.modal_switch_membership.showModal();
                this.loadAvailableMemberships();
            },

            async loadAvailableMemberships() {
                let data = {
                    param_member_no: this.user_data.id
                };
                this.showLoading('component');
                await apiCall("/default/getMembershipTypes", {data}).then(res => {
                    this.available_memberships = res;
                });
                this.hideLoading('component');
            },

            async submitMembershipSwitch() {
                if (this.original_membership_type === this.switch_to_type &&
                    this.switching_from_order
                ) {
                    this.$refs.modal_switch_membership.close();
                    // this.pay(this.switching_from_order);
                }

                this.showLoading('component');
                const result = await apiCall("/default/switchMembershipFromOrder", {
                    data: {
                        param_member_no: this.user_data.id,
                        param_existing_order_id: this.switching_from_order,
                        param_new_membershiptype_id: this.switch_to_type
                    }
                });

                // Reload order list
                await this.loadOrders();

                this.hideLoading('component');
                this.$refs.modal_switch_membership.close();

                if (result && result.order_id) {
                    this.pay(result.order_id);
                }
            },

            ...mapActions({
                showLoading: 'loader/show',
                hideLoading: 'loader/hide',
            })
        }
    }
</script>
