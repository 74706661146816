<template>
    <div class="gutter">
        <div class="page-header">
            <h1 class="headline blue">Donera</h1>
            <div v-if="success != 'success'">
                <p>Vi har gott om idéer men ont om resurser. Liberalerna behöver mer pengar för att kunna nå ut till väljarna och få
                    fler röster. Vi behöver din hjälp. Med regelbundna donationer kan Liberalerna planera en stark valrörelse. Bli månadsgivare redan
                    idag - du gör skillnad.</p>
                <p>
                    <br/>
                    Läs mera om att bidra: <a href="https://www.liberalerna.se/bidra/" target="_blank">https://www.liberalerna.se/bidra/</a>
                </p>
            </div>
        </div>
        <Loader type="component" />
        <div class="content" v-if="success != 'success'">
            <div class="button-toolbar">
                <button class="blue" v-on:click="$refs.modal_month_donate.showModal()">
                    Bli månadsgivare
                </button>
                <button class="blue" v-on:click="$refs.modal_donate.showModal()">
                    Ge en gåva
                </button>
                <button v-on:click="$refs.modal_donations.showModal()">
                    Dina donationer
                </button>
            </div>

            <Modal ref="modal_month_donate" type="window">
                <template slot="header">
                    <h3>Bli månadsgivare</h3>
                </template>
                <template slot="body">
                    <div id="new_donation">
                        <p>Vi kommer skicka en digital faktura till dig den 20:e varje månad.</p>
                        <div id="amount_picker">
                            <div class="amount">
                                <div><strong>Summa:&nbsp;</strong></div>
                                <div><select v-model="amount">
                                    <option v-for="(amount, key) in amounts" :key="key" :value="amount">{{ amount }} KR</option>
                                </select></div>
                            </div>
                        </div>
                        <div id="bank_picker">
                            <div class="bank">
                                <div><strong>Bank:&nbsp;</strong></div>
                                <div><select v-model="bank">
                                    <option v-for="(bank, key) in banks" :key="key" :value="bank.value">{{ bank.name }}</option>
                                </select></div>
                            </div>
                        </div>
                        <div class="button-toolbar">
                            <div id="accept-tos-wrapper">
                                <input type="checkbox" name="accept_tos" id="accept-tos" v-model="accept_tos">
                                <label for="accept-tos">Jag accepterar <a href="https://www.liberalerna.se/anvandarvillkor" target="_blank">användarvillkoren</a></label>
                            </div>

                            <button class="blue" v-on:click="initDonation" :disabled="!accept_tos" :class="{'inactive': !accept_tos}">
                                <i class="mdi mdi-gift-outline"></i>
                                Starta månadsdonation
                            </button>
                        </div>
                    </div>
                </template>
            </Modal>

            <Modal ref="modal_account_donate" type="window">
                <template slot="header">
                    <h3>Välj konto för autogiro</h3>
                </template>
                <template slot="body">
                    <div v-if="accountNumbers.length > 0" style="padding:20px 0;">
                        <button class="blue"  v-for="(account, key) in accountNumbers" v-on:click="selectAccount(key)">{{ account.Name }} ({{ account.Number }})</button>
                    </div>
                    <div v-else style="padding:20px 0;">
                        <div style="display: block">
                            Öppna ditt Bank-id för att verifiera dig.<br >
                          <!-- button opens this link: https://app.bankid.com/?autostarttoken=[TOKEN]&redirect=[RETURNURL]-->
                          <a v-if="BankIdAutostartToken" class="blue" :href="'https://app.bankid.com/?autostarttoken='+BankIdAutostartToken+'&redirect=null'">Öppna Bank-id appen</a>
                        </div>
                        <img v-if="qrCode" style="width: 25%;display: block; padding-top:20px;margin:0 auto;" :src="qrCode" alt="QR Code">
                    </div>
                </template>
            </Modal>

            <Modal ref="modal_donate" type="window">
                <template slot="header">
                    <h3>Ge gåva</h3>
                </template>
                <template slot="body">
                    <div class="gift">
                        <p>Ett enkelt sätt att stödja liberala idéer. Swisha till: <strong>123 148 12 41</strong>. Skriv <strong>”Gåva”</strong> samt ditt personnummer i meddelandet.</p>
                    </div>
                </template>
            </Modal>

        </div>
        <div id="thank_you" v-if="success === 'success'">
            <h1 class="headline blue">Tack!</h1>
            <div class="status-icon">
                <img src="@/assets/happy.svg" title="Happy" />
            </div>
            <p>Din gåva uppskattas mycket!</p>
            <br/>
            <button class="blue" v-on:click="success = false">Varsågoda!</button>
        </div>

        <Modal ref="modal_donations" type="window">
            <template slot="header">
                <h3>Dina donationer</h3>
            </template>
            <template slot="body">
                <div class="content" v-if="has_donations">
                    <p>Nedan hittar du en lista på dina donationer</p>
                    <table>
                        <thead>
                        <tr>
                            <th style="text-align: left">Startad</th>
                            <th style="text-align: left">Summa</th>
                            <th style="text-align: left">Senaste dragning</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tr v-for="(donation,key) in donations" :key="key">
                            <td>{{ donation.creation_date }}</td>
                            <td>{{ donation.amount }} KR</td>
                            <td>{{ donation.last_withdrawal }}</td>
                            <td>
                                <span v-if="delete_sure === donation.id">Är du säker? <a v-on:click="endDonation(donation.id)">Ja, tyvärr</a> / <a v-on:click="sureDelete(donation.id)">Nej, avbryt</a></span>
                                <i v-if="delete_sure !== donation.id" v-on:click="sureDelete(donation.id)" class="mdi mdi-delete-circle"></i></td>
                        </tr>
                    </table>
                </div>
            </template>
        </Modal>

    </div>
</template>

<script>
import apiCall from "@/utils/api";
import Loader from "@/components/Loader";
import {mapActions, mapState} from "vuex";
import Modal from "@/components/Modal";

export default {
        name: 'Links',
        mounted() {
            this.loadDonations();
        },
        computed: {
          ...mapState({
            user_data: state => state.user.data
          })
        },
        components: {
            Loader,
            Modal
        },
        data(){
            return {
                amount: 50,
                bank: "OEB",
                bankIdIntervall: null,
                accountNumbers: [],
                qrCode: null,
                BankIdAutostartToken: null,
                amounts: [
                    50,100,250,500,1000
                ],
                banks: [
                  {
                    "name": "Danske Bank",
                    "value": "OEB"
                  },
                  {
                    "name": "Handelsbanken",
                    "value": "SHB"
                  },
                  {
                    "name": "Ica",
                    "value": "ICA"
                  },
                  {
                    "name": "Länsforsakringar",
                    "value": "LFB"
                  },
                  {
                    "name": "Nordea",
                    "value": "NB"
                  },
                  {
                    "name": "SEB",
                    "value": "SEB"
                  },
                  {
                    "name": "Skandia",
                    "value": "SKB"
                  },
                  {
                    "name": "Sparbanken Syd",
                    "value": "SYD"
                  },
                  {
                    "name": "Swedbank",
                    "value": "FSPA"
                  },
                  {
                    "name": "Swedbank Sparbankerna",
                    "value": "FSPASB"
                  }
                ],
                has_donations: false,
                donations: [],
                delete_sure: 0,
                baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/",
                success: this.$route.params.success,
                accept_tos: false
            }
        },
        methods: {
            loadDonations() {
                apiCall("/user/getMemberDonations").then(res => {
                    if(typeof res.success !== "undefined" && res.success === true) {
                        this.has_donations = true;
                        this.donations = res.donations;
                    } else {
                        this.has_donations = false;
                        this.donations = [];
                    }
                });
            },
            selectAccount(accountIndex) {
              let data = {
                id: this.user_data.id,
                amount: this.amount,
                account: this.accountNumbers[accountIndex],
                bank: this.bank
              };
              this.showLoading("component");
              apiCall("/user/createRecurringDonation", {
                data
              }).then(res => {
                this.hideLoading("component");
                this.$refs.modal_account_donate.close();
                this.$notify({
                  type: 'info',
                  group: 'general',
                  title: 'Tack!',
                  text: 'Tack för att du väljer att donerat till oss',
                  duration: 5000,
                  speed: 1000
                });
              });
            },
            verifyWithBank(pid) {
                let data = {
                    pid: pid
                };
                this.$refs.modal_account_donate.showModal();
                apiCall("/user/validateBankAccount", {data}).then(res => {
                  console.log(res);
                  if( res.BankIdAutostartToken) {
                    this.BankIdAutostartToken = res.BankIdAutostartToken;
                  }
                  if(res.QR !== undefined) {
                    this.qrCode = res.QR; // This is a data:image/svg+xml;base64
                  }
                  if (res.AccountNumbers.length > 0) {
                    this.accountNumbers = res.AccountNumbers;
                  } else {
                    setTimeout(() => {
                      this.verifyWithBank(pid); // Retry verification after 2500 milliseconds
                    }, 800);
                  }
                });
            },
            sureDelete(id) {
                if(this.delete_sure === id) { this.delete_sure = 0 } else { this.delete_sure = id}
            },
            endDonation(id){
                let data = {
                    id: id
                };
                apiCall("/user/endDonation", {data}).then(res => {
                    if(typeof res.success !== "undefined" && res.success === true) {
                        this.$notify({
                            type: 'info',
                            group: 'general',
                            title: 'Tack!',
                            text: 'Tack för att du har donerat till oss, du är alltid välkommen tillbaka.',
                            duration: 10000,
                            speed: 1000
                        });
                        this.delete_sure = 0;
                        this.loadDonations();
                    }
                });
            },
            initDonation() {
              if (!this.accept_tos) {
                return;
              }
              if (this.amount > 0) {
                let data = {
                  id: this.user_data.id,
                  amount: this.amount,
                  bank: this.bank
                };
                this.showLoading("component");
                apiCall("/user/initRecurringDonation", {
                  data
                }).then(res => {
                  console.log(res);
                  this.hideLoading("component");
                  this.$refs.modal_month_donate.close();
                  if (res.publicId !== undefined) {
                    this.verifyWithBank(res.publicId);
                  } else {
                    alert("Fail");
                  }
                  // this.$notify({
                  //   type: 'info',
                  //   group: 'general',
                  //   title: 'Tack!',
                  //   text: 'Tack för att du väljer att donerat till oss',
                  //   duration: 5000,
                  //   speed: 1000
                  // });
                });
              }
            },
            ...mapActions({
                showLoading: 'loader/show',
                hideLoading: 'loader/hide',
            })
        }
    }
</script>
